
import { defineComponent, ref, reactive, UnwrapRef, onMounted } from 'vue'
import { notification } from 'ant-design-vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { SettingOutlined } from '@ant-design/icons-vue'

import validate from '../../../services/validator'
import appServices from '../../../services/appSettings'

interface AppSettings {
  youtube_url1: string;
  youtube_url2: string;
  youtube_url3: string;
}

export default defineComponent({
  components: {
    SettingOutlined
  },
  setup () {
    const loading = ref<boolean>(false)
    const appFormRef = ref()
    const appRules = validate.appSettingsValidation
    const youtubeURLS = ref()
    const appForm: UnwrapRef<AppSettings> = reactive({
      youtube_url1: '',
      youtube_url2: '',
      youtube_url3: '',
      client_logo: ''
    })
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const getAppSettingsBySlug = async (slug) => {
      try {
        const responce = await appServices.getBySlug(slug)
        if (responce.data) {
          if (responce.data.slug === 'client-dashboard-youtube-url') {
            youtubeURLS.value = responce.data.value.split(',')
            let i = 1
            for (const url of youtubeURLS.value) {
              appForm['youtube_url' + i] = url
              i++
            }
          }
        }
        console.log('youtubeURLS.value', youtubeURLS.value)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const updateAppSettings = async (data) => {
      try {
        console.log('data', data)
        const url = data.youtube_url1 + ',' + data.youtube_url2 + ',' + data.youtube_url3
        await appServices.addOrUpdate({ slug: 'client-dashboard-youtube-url', value: url })
        await notify('Success', 'Updated successfully', 'success')
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const validateForm = async () => {
      appFormRef.value
        .validate()
        .then(() => {
          updateAppSettings(appForm)
        })
        .catch((error: ValidateErrorEntity<AppSettings>) => {
          console.log('error', error)
        })
    }
    onMounted(() => {
      getAppSettingsBySlug('client-dashboard-youtube-url')
    })
    return {
      loading,
      appFormRef,
      appRules,
      appForm,
      validateForm,
      youtubeURLS
    }
  }
})
