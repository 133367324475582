<template>
  <div class="main">
    <div class="settings content-body">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <SettingOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Settings
        </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>System Settings</h4>
        </a-col>
      </a-row>
      <a-row class="body">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <a-form ref="appFormRef" :model="appForm" :rules="appRules" layout="vertical">
            <a-form-item label="Youtube URL" required has-feedback name="youtube_url1">
              <a-input v-model:value="appForm.youtube_url1" size="large" type="text" placeholder="Enter youtube URL" allow-clear autocomplete="off" />
            </a-form-item>
            <a-form-item label="Youtube URL" required has-feedback name="youtube_url2">
              <a-input v-model:value="appForm.youtube_url2" size="large" type="text" placeholder="Enter youtube URL" allow-clear autocomplete="off" />
            </a-form-item>
            <a-form-item label="Youtube URL" required has-feedback name="youtube_url3">
              <a-input v-model:value="appForm.youtube_url3" size="large" type="text" placeholder="Enter youtube URL" allow-clear autocomplete="off" />
            </a-form-item>
          </a-form>
          <a-button type="primary" class="updateBtn" @click.prevent="validateForm">Update</a-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, UnwrapRef, onMounted } from 'vue'
import { notification } from 'ant-design-vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { SettingOutlined } from '@ant-design/icons-vue'

import validate from '../../../services/validator'
import appServices from '../../../services/appSettings'

interface AppSettings {
  youtube_url1: string;
  youtube_url2: string;
  youtube_url3: string;
}

export default defineComponent({
  components: {
    SettingOutlined
  },
  setup () {
    const loading = ref<boolean>(false)
    const appFormRef = ref()
    const appRules = validate.appSettingsValidation
    const youtubeURLS = ref()
    const appForm: UnwrapRef<AppSettings> = reactive({
      youtube_url1: '',
      youtube_url2: '',
      youtube_url3: '',
      client_logo: ''
    })
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const getAppSettingsBySlug = async (slug) => {
      try {
        const responce = await appServices.getBySlug(slug)
        if (responce.data) {
          if (responce.data.slug === 'client-dashboard-youtube-url') {
            youtubeURLS.value = responce.data.value.split(',')
            let i = 1
            for (const url of youtubeURLS.value) {
              appForm['youtube_url' + i] = url
              i++
            }
          }
        }
        console.log('youtubeURLS.value', youtubeURLS.value)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const updateAppSettings = async (data) => {
      try {
        console.log('data', data)
        const url = data.youtube_url1 + ',' + data.youtube_url2 + ',' + data.youtube_url3
        await appServices.addOrUpdate({ slug: 'client-dashboard-youtube-url', value: url })
        await notify('Success', 'Updated successfully', 'success')
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const validateForm = async () => {
      appFormRef.value
        .validate()
        .then(() => {
          updateAppSettings(appForm)
        })
        .catch((error: ValidateErrorEntity<AppSettings>) => {
          console.log('error', error)
        })
    }
    onMounted(() => {
      getAppSettingsBySlug('client-dashboard-youtube-url')
    })
    return {
      loading,
      appFormRef,
      appRules,
      appForm,
      validateForm,
      youtubeURLS
    }
  }
})
</script>

<style lang="scss">
.settings {
  .header {
    background: #fff;
    padding: 15px 20px;
    margin: 20px 0px 10px;
    border-radius: 8px;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Medium";
      font-size: 18px;
      text-align: left;
      margin: 0;
    }
  }
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    .has-error .ant-form-explain {
      text-align: left;
    }
    .ant-input-affix-wrapper{
      border-radius: 5px;
    }
    .ant-form {
      margin: 50px auto;
      justify-content: space-between;
      label {
        color: #646464 !important;
      }
    }
    .updateBtn {
      background: #72CF97;
      border: 1px solid #72CF97;
      border-radius: 5px;
      width: 250px;
      margin: 0 0 30px 0;
    }
  }
}

</style>
